/*global gtag*/
import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { usePageSetup } from "@components/usePageSetup"
import { Layout } from "@components/Layout"
import { Seo } from "@components/Seo"
import { Arrow } from "@components/UI"
import { ClientList } from "@components/ClientList"
import { AltAboutJk } from "@components/AboutJk"
import { SalesForm } from "@components/Form"
import { Lottie } from "@components/Lottie"
import { Button } from "@components/Button"
import { Carousel } from "@components/Carousel"
import { CursorMarker } from "@components/CursorMarker"
import { splitText } from "@utils"
import { PillButton } from "@components/Button"
import megaphoneLottie from "@lottie/megaphone_c.json"
import blockheartsLottie from "@lottie/blockhearts_c.json"
import flowLottie from "@lottie/flow_b.json"
import HeroShapeOne from "@svg/b2b-branding/hero-shape-01.svg"
import HeroShapeTwo from "@svg/b2b-branding/hero-shape-02.svg"

const B2BBrandingPage = ({ data }) => {
  const { weichertDesktop, weichertMobile, ansDesktop, ansMobile, aboutJkImage, heroImage } = data
  const { themeColor } = usePageSetup({ themeColor: "bg-blue-light", displayGeneralForm: false })

  const contactSubmission = () => {
    const isBrowser = typeof window !== "undefined"
    if (!isBrowser) {
      return
    }
    gtag("event", "talk_to_b2b_expert", { event_category: "contact_us", event_label: "jk_expert_contact_submit" })
  }

  const getTheGuide = () => {
    const isBrowser = typeof window !== "undefined"
    if (!isBrowser) {
      return
    }
    gtag("event", "get_the_guide", { event_category: "click", event_label: "brand_position_ebook" })
    window.open("/documents/jk-brand-position-e-book-2022.pdf", "_blank").focus()
  }

  return (
    <Layout>
      <Seo title="B2B Branding" description="" />
      <div className="overflow-hidden">
        <div className={themeColor} style={{ height: "94px" }}></div>
        <div className={themeColor}>
          <div className="container relative">
            <div className="absolute inset-x-0 bottom-0">
              <div className="aspect-w-326 aspect-h-93 sm:aspect-w-667 lg:aspect-w-1436 sm:aspect-h-78 extend before:bg-white-dark after:bg-white-dark"></div>
            </div>

            <div className="relative sg-hero-grid">
              <div className="mb-8 sg-title pt-14">
                <div>
                  <div data-aos="fade">
                    <PillButton staticButton={true}>B2B Branding</PillButton>
                  </div>
                  <h1 className="mt-12 text-clamp-30-35" data-aos="fade-up">
                    Stay relevant and increase engagement with a fresh B2B brand that showcases the value your business
                    delivers.
                  </h1>
                </div>
              </div>
              <div className="pb-15 sm:pb-40 sg-message" data-aos="fade-up">
                <p className="text-gray-darkest text-clamp-18-22">
                  We’re experts in crafting and implementing authentic brand stories and standout visual identities that
                  make meaningful connections with the customers who drive your success.
                </p>
                <div>
                  <Button className="mt-8 text-gray-darkest" link="#b2b-form">
                    Get in touch with an expert now
                  </Button>
                </div>
              </div>
              <div className="flex sg-image">
                <div className="grid self-end w-full h-full grid-cols-8 sm:grid-cols-6">
                  <div className="relative col-span-7 sm:col-span-6 lg:col-span-5 lg:col-start-2">
                    <div className="inset-0 pb-6 sm:flex sm:pl-5 sm:absolute lg:pl-0 lg:pr-5 sm:pb-0">
                      <div className="relative self-end w-full h-full lg:w-auto lg:h-auto" data-aos="fade-left">
                        <div className="absolute inset-y-0 right-0 flex">
                          <div className="transform scale-110 translate-x-8 sm:translate-x-2 lg:translate-x-8">
                            <HeroShapeOne />
                          </div>
                        </div>
                        <GatsbyImage
                          objectFit="contain"
                          className="w-full h-full"
                          image={getImage(heroImage)}
                          alt="Social good storytelling"
                        />
                        <div className="absolute inset-y-0 right-0 flex">
                          <div className="transform scale-110 translate-x-8 sm:translate-x-2 lg:translate-x-8">
                            <HeroShapeTwo />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <main>
          <section className="pb-clamp-28-33">
            <div className="container">
              <div className="flex lg:justify-center">
                <div>
                  <div>
                    <PillButton staticButton={true} className="bg-teal-light">
                      Our services
                    </PillButton>
                  </div>
                  <div className="grid lg:grid-cols-10 gap-x-4 gap-y-8 md:gap-y-16 mt-14">
                    <div data-aos="fade-up" className="items-center lg:col-span-3 sm:flex lg:flex-col">
                      <div className="flex w-full mr-auto" style={{ height: "112px", maxWidth: "175px" }}>
                        <Lottie animation={blockheartsLottie} width="94px" />
                      </div>
                      <div>
                        <h3 className="mt-10 text-clamp-18-22 sm:mt-0 lg:mt-8">Brand development</h3>
                        <p className="text-16 mt-clamp-6-8">
                          Our proven process delivers a brand identity that sets you apart—whether you’re rebranding
                          your business, rethinking your product portfolio, or creating a powerful new position for a
                          business division.
                        </p>
                      </div>
                    </div>
                    <div data-aos="fade-up" data-aos-delay="200" className="flex flex-col lg:col-span-4">
                      <div className="items-center lg:w-3/4 lg:mx-auto sm:flex lg:flex-col">
                        <div className="flex w-full mr-auto" style={{ height: "112px", maxWidth: "175px" }}>
                          <Lottie animation={flowLottie} width="125px" />
                        </div>
                        <div>
                          <h3 className="mt-10 text-clamp-18-22 sm:mt-0 lg:mt-8">Brand rollout</h3>
                          <p className="text-16 mt-clamp-6-8">
                            From research to strategy to creative campaigns, we’ll work with you to ensure that your new
                            brand engages the right people at the right times and places.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      data-aos="fade-up"
                      data-aos-delay="400"
                      className="items-center lg:col-span-3 gap-x-6 sm:flex lg:flex-col"
                    >
                      <div className="flex mr-auto lg:w-full" style={{ height: "112px", maxWidth: "175px" }}>
                        <Lottie animation={megaphoneLottie} width="147px" />
                      </div>
                      <div>
                        <h3 className="mt-10 text-clamp-18-22 sm:mt-0 lg:mt-8">Brand execution</h3>
                        <p className="text-16 mt-clamp-6-8">
                          As an end-to-end creative marketing agency, we can bring your brand to life across every
                          touchpoint—or become an extension of your internal creative team.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="bg-blue-lighter py-clamp-18-34">
            <div className="container">
              <div className="flex lg:justify-center">
                <div className="grid lg:gap-16 lg:grid-cols-2">
                  <div>
                    <p className="font-bold text-clamp-25-45">
                      86% of people engage with a brand based on one critical factor: authenticity.*
                    </p>
                    <p className="font-bold text-20">*2022 Stackla survey of 2,000 adults</p>
                  </div>
                  <div className="mt-7 lg:mt-0">
                    <p className="text-clamp-18-28">
                      That’s why JK works to truly understand your business, your customers, and your competition—so we
                      can tell your best, most authentic B2B brand story, one that reflects who you truly are and the
                      unique value you create. Backed by over 35 years of B2B branding experience and a proven,
                      insight-driven process, we’re ready to build a brand that builds your business.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Arrow colorTop="bg-gray-darkest" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />

          <section className="bg-white-dark py-clamp-24-28">
            <div className="container">
              <div className="flex lg:justify-center">
                <div className="lg:w-10/12">
                  <div className="grid-cols-10 sm:grid">
                    <div className="col-span-8">
                      <h2
                        className="text-clamp-35-45"
                        data-aos="stagger"
                        dangerouslySetInnerHTML={{
                          __html: splitText({
                            "text-blue-light": "Looking for information and inspiration? ",
                            "text-gray-darkest": "We have you covered.",
                          }),
                        }}
                      />
                    </div>
                  </div>
                  <div className="grid mt-16 md:grid-cols-2 lg:grid-cols-10 gap-x-4 gap-y-8 md:gap-y-16">
                    <div data-aos="fade-up" className="flex flex-col lg:col-span-3">
                      <p>8 fundamentals to building a successful brand.</p>
                      <div className="mt-auto">
                        <Button
                          func={getTheGuide}
                          link="/documents/jk-brand-position-e-book-2022.pdf"
                          external={true}
                          className="sm:hover:text-gray-darkest"
                        >
                          Get the guide
                        </Button>
                      </div>
                    </div>
                    <div className="flex flex-col lg:col-span-4">
                      <div data-aos="fade-up" className="flex flex-col lg:w-3/4 lg:mx-auto lg:h-full">
                        <p className="pb-6">Why your company needs to rebrand (or doesn’t).</p>
                        <div className="mt-auto">
                          <Button
                            func={getTheGuide}
                            link="/documents/jk-b2b-branding-guide-2022.pdf"
                            external={true}
                            className="sm:hover:text-gray-darkest"
                          >
                            Get the guide
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div data-aos="fade-up" className="flex flex-col lg:col-span-3">
                      <p className="pb-6">Attract and convert more B2B customers with the right brand story.</p>
                      <div className="mt-auto">
                        <Button
                          link="/ideas-and-insights/attract-and-convert-more-b2b-customers-with-the-right-brand-story/"
                          className="sm:hover:text-gray-darkest"
                        >
                          Read the blog
                        </Button>
                      </div>
                    </div>
                    <div data-aos="fade-up" className="flex flex-col lg:col-span-3">
                      <p className="pb-6">How to tell—and sell—your B2B brand story.</p>
                      <div className="mt-auto">
                        <Button
                          link="/ideas-and-insights/how-to-tell-and-sell-your-b2b-brand-story/"
                          className="sm:hover:text-gray-darkest"
                        >
                          Read the blog
                        </Button>
                      </div>
                    </div>
                    <div data-aos="fade-up" className="lg:col-span-4">
                      <div className="flex flex-col lg:w-3/4 lg:mx-auto">
                        <p className="pb-6">
                          The truth is: emotional storytelling in B2B marketing works. Here are three clear advantages.
                        </p>
                        <div className="mt-auto">
                          <Button
                            link="/ideas-and-insights/the-truth-is-emotional-storytelling-in-b2b-marketing-works-here-are-three-clear-advantages/"
                            className="sm:hover:text-gray-darkest"
                          >
                            Read the blog
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div data-aos="fade-up" className="flex flex-col lg:col-span-3">
                      <p className="pb-6">Is your B2B brand ready for a refresh?</p>
                      <div className="mt-auto">
                        <Button
                          link="/ideas-and-insights/is-your-b2b-brand-ready-for-a-refresh/"
                          className="sm:hover:text-gray-darkest"
                        >
                          Read the blog
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Arrow colorTop="bg-transparent" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />
          <section className="bg-gray-darkest py-clamp-18-25">
            <div className="container">
              <div className="flex lg:justify-center">
                <div className="w-full">
                  <div data-aos="fade">
                    <PillButton staticButton={true}>Our clients & testimonials</PillButton>
                  </div>
                  <h2
                    className="mt-12 text-clamp-35-45"
                    data-aos="stagger"
                    dangerouslySetInnerHTML={{
                      __html: splitText({
                        "text-teal": "Stories we’ve told ",
                        "text-white-dark": "along the way.",
                      }),
                    }}
                  />
                  <ClientList
                    className="text-white fill-current gap-x-6 lg:gap-x-16 mt-clamp-9-8"
                    clients={[
                      "ad",
                      "j-and-j",
                      "heineken",
                      "dupont",
                      "ans",
                      "philips",
                      "lg",
                      "amgen",
                      "lumendi",
                      "weichert",
                      "bpm",
                    ]}
                  />
                </div>
              </div>
            </div>
          </section>
          <section className="relative overflow-hidden pb-clamp-20-42 bg-gray-darkest">
            <div className="absolute inset-0 flex">
              <div className="w-full mt-auto h-1/2 bg-white-dark"></div>
            </div>
            <div className="container relative flex">
              <div className="w-full sm:w-10/12 lg:mx-auto">
                <Carousel>
                  <CursorMarker>
                    <CursorMarker.Default className="bg-white after:text-purple" />
                  </CursorMarker>
                  <Carousel.Swiper>
                    <Carousel.Slide>
                      <div data-aos="fade-up" data-aos-delay={200 * 0}>
                        <Carousel.Quote client="ans"></Carousel.Quote>
                      </div>
                    </Carousel.Slide>
                    <Carousel.Slide>
                      <div data-aos="fade-up" data-aos-delay={200 * 1}>
                        <Carousel.Quote client="ad"></Carousel.Quote>
                      </div>
                    </Carousel.Slide>
                  </Carousel.Swiper>
                </Carousel>
              </div>
            </div>
            <Arrow colorTop="bg-transparent" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />
          </section>
          <section className="bg-teal-light pt-clamp-20-44 pb-clamp-23-37">
            <div className="container">
              <div className="flex xl:justify-center">
                <div className="w-9/12 xl:w-10/12">
                  <h2
                    className="text-clamp-35-62"
                    data-aos="stagger"
                    dangerouslySetInnerHTML={{
                      __html: splitText({
                        "text-gray-darkest": "See our storytelling in action.",
                      }),
                    }}
                  />
                </div>
              </div>
              <div className="mt-clamp-15-28">
                <div className="hidden md:flex lg:justify-center">
                  <div className="grid grid-cols-2 xl:w-10/12 gap-x-4">
                    <div data-aos="fade-up" className="flex flex-col">
                      <div className="grid grid-cols-10">
                        <div className="flex flex-col col-span-9">
                          <h3 className="font-semi text-22">Weichert</h3>
                          <GatsbyImage
                            className="mt-10"
                            objectFit="contain"
                            image={getImage(weichertDesktop)}
                            alt="Weichert"
                          />
                        </div>
                      </div>
                      <div className="grid h-full grid-cols-10">
                        <div className="flex flex-col col-span-8">
                          <h4 className="mt-14 text-22 lg:pr-20">
                            Creating a standout new story that creates new business possibilities.
                          </h4>
                          <p className="mt-6 text-18 pb-9">
                            Sometimes connecting with B2B prospects takes more than just a sharp new website—it takes
                            telling a whole new story.
                          </p>
                          <div className="mt-auto">
                            <Button link="/work/weichert" className="sm:hover:text-gray-darkest">
                              Read the full case study
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div data-aos="fade-up" data-aos-delay="200" className="flex flex-col">
                      <div data-aos="fade-up" className="grid grid-cols-10">
                        <div className="flex flex-col col-start-2 col-end-11">
                          <h3 className="font-semi text-22">ANS</h3>
                          <GatsbyImage className="mt-10" objectFit="contain" image={getImage(ansDesktop)} alt="ANS" />
                        </div>
                      </div>
                      <div className="grid h-full grid-cols-10">
                        <div className="flex flex-col col-start-2 col-end-10">
                          <h4 className="mt-14 text-22 lg:pr-20">
                            Pioneering a powerful new brand–and a more engaging website.
                          </h4>
                          <p className="mt-6 text-18 pb-9">
                            With a unique approach to their work and a reputation for excellence, ANS had an original
                            and compelling story to tell. But they needed JK to help them find their voice among louder,
                            larger competitors.
                          </p>
                          <div className="mt-auto">
                            <Button link="/work/ans" className="sm:hover:text-gray-darkest">
                              Read the full case study
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full md:hidden">
                  <Carousel>
                    <CursorMarker>
                      <CursorMarker.Default className="bg-white after:text-purple" />
                    </CursorMarker>
                    <Carousel.Swiper>
                      <Carousel.Slide>
                        <div className="grid w-full grid-cols-12">
                          <div className="col-span-11">
                            <div data-aos="fade-up" className="flex flex-col">
                              <div data-aos="fade-up">
                                <div className="flex flex-col">
                                  <h3 className="font-semi text-22">Weichert</h3>
                                  <GatsbyImage
                                    className="mt-10"
                                    objectFit="contain"
                                    image={getImage(weichertMobile)}
                                    alt="Weichert"
                                  />
                                </div>
                              </div>
                              <div>
                                <div className="flex flex-col">
                                  <h4 className="mt-14 text-22 lg:pr-20">
                                    Creating a standout new story that creates new business possibilities.
                                  </h4>
                                  <p className="mt-6 text-18 pb-9">
                                    Sometimes connecting with B2B prospects takes more than just a sharp new website—it
                                    takes telling a whole new story.
                                  </p>
                                  <div className="mt-auto">
                                    <Button link="/work/weichert" className="sm:hover:text-gray-darkest">
                                      Read the full case study
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Carousel.Slide>
                      <Carousel.Slide>
                        <div className="grid w-full grid-cols-12">
                          <div className="col-span-11">
                            <div data-aos="fade-up" data-aos-delay="200" className="flex flex-col">
                              <div data-aos="fade-up">
                                <div className="flex flex-col">
                                  <h3 className="font-semi text-22">ANS</h3>
                                  <GatsbyImage
                                    className="mt-10"
                                    objectFit="contain"
                                    image={getImage(ansMobile)}
                                    alt="ANS"
                                  />
                                </div>
                              </div>
                              <div>
                                <div className="flex flex-col">
                                  <h4 className="mt-14 text-22 lg:pr-20">
                                    Pioneering a powerful new brand–and a more engaging website.
                                  </h4>
                                  <p className="mt-6 text-18 pb-9">
                                    With a unique approach to their work and a reputation for excellence, ANS had an
                                    original and compelling story to tell. But they needed JK to help them find their
                                    voice among louder, larger competitors.
                                  </p>
                                  <div className="mt-auto">
                                    <Button link="/work/ans" className="sm:hover:text-gray-darkest">
                                      Read the full case study
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Carousel.Slide>
                    </Carousel.Swiper>
                  </Carousel>
                </div>
              </div>
            </div>
          </section>
          <Arrow colorTop="bg-transparent" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />
          <section className="pt-clamp-44-62 pb-clamp-37-60">
            <AltAboutJk>
              <AltAboutJk.Content>
                JK helps B2B companies of all types and sizes discover and build the foundation of their story, give it
                an authentic voice and distinctive look, and tell it in unique, compelling, and strategic ways that
                connect with the people who matter most.
              </AltAboutJk.Content>
              <AltAboutJk.ImageV2 image={aboutJkImage} shape="one" />
            </AltAboutJk>
          </section>
          <Arrow colorTop="bg-gray-darkest" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />
          <div id="b2b-form" style={{ scrollMarginTop: "78px" }}></div>
          <section className="bg-blue-light pt-clamp-34-51 pb-clamp-27-40">
            <div className="container flex lg:justify-center">
              <div className="xl:w-10/12">
                <div className="grid-cols-10 lg:grid">
                  <div className="col-span-7">
                    <h2
                      data-aos="stagger"
                      className="text-title"
                      dangerouslySetInnerHTML={{
                        __html: splitText({
                          "text-white": "Let’s build a ",
                          "text-gray-darkest": "stronger talent story, together.",
                        }),
                      }}
                    />
                  </div>
                </div>
                <div className="grid-cols-10 lg:grid mt-clamp-7-11">
                  <div className="col-span-8 xl:col-span-7">
                    <p data-aos="fade-up">Please fill out the form below and we’ll be in touch soon.</p>
                  </div>
                </div>
              </div>
            </div>
            <section className="container mt-24">
              <div className="mx-auto xl:w-10/12">
                <SalesForm
                  id="632"
                  subject="JK Design - B2B Branding"
                  recipient="katiek@jkdesign.com"
                  fieldColor="bg-teal-light"
                  fn={contactSubmission}
                />
              </div>
            </section>
          </section>
        </main>
      </div>
    </Layout>
  )
}

export default B2BBrandingPage

export const B2BBrandingQuery = graphql`
  query b2bBrandingQuery {
    heroImage: file(relativePath: { eq: "b2b/hero.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    bannerDesktop: file(relativePath: { eq: "employee-branding/banner-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    bannerMobile: file(relativePath: { eq: "employee-branding/banner-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    weichertDesktop: file(relativePath: { eq: "b2b/weichert-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    weichertMobile: file(relativePath: { eq: "b2b/weichert-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    ansDesktop: file(relativePath: { eq: "b2b/ans-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    ansMobile: file(relativePath: { eq: "b2b/ans-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    aboutJkImage: file(relativePath: { eq: "global/about-jk.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
